
import { Component, Prop } from "vue-property-decorator";
import { getComponent, getConfigEnv, jsonParse, sleep } from "@/utils/helpers";
import { mixins } from "vue-class-component";
// import AWS from 'aws-sdk';
// import {
//   POST_SHAPEFILE_LAMBDA_REGION,
//   IDENTITY_POOL_REGION,
//   IDENTITY_POOL_ID,
// } from '@/lib/constants';
import DataLoading from "../../lib/mixins/dataLoading";
import TasqJob from "../../interfaces/tasqs/TasqJob";
// import setpointV2Module from '@/store/modules/setpointV2Module';
// import tasqFeedbackModule from '@/store/modules/tasqFeedbackModule';
// import metaDataModule from '@/store/modules/metaDataModule';
// import tasqsListModule from '@/store/modules/tasqsListModule';
import {  randomAssetNameForDemo } from '@/utils/helpers';
// import workflowModule from '@/store/modules/workflowModule';
import Multiselect from "vue-multiselect";
import axiosClient from "@/lib/rest/axiosClient";
import GptMixin from "./GptMixin";
import gptModule from "@/store/modules/gptModule";
import setpointV2Module from "@/store/modules/setpointV2Module";

@Component({
  components: {
    SpinnerLoader: () => getComponent("loaders/SpinnerLoader"),
    TasqSetpointFeedback: () => getComponent("tasqs/TasqSetpointFeedback"),
    Multiselect,
  },
})
export default class SetpointFeedbackV3 extends mixins(DataLoading, GptMixin) {
  @Prop({ required: true }) tasq!: TasqJob;

  isLoadingPlot = false;

  showTpLpPopup = false;

  showTpPopup = false;

  refreshingList = false;

  randomAssetNameForDemo = randomAssetNameForDemo;

  selectedInput = "";

  acceptedValue = "";

  message = "";

  isSavingData = false;

  showAllEvents = false;

  isLoadingData = false;

  updateList() {
    this.refreshingList = true;
    this.showAllEvents = !this.showAllEvents;

    this.$nextTick(() => {
      this.refreshingList = false;
    });
  }

  get isSetpointSummaryLoading(){
    return gptModule.isLoadingSummary
  }

  get isSetpointTasq() {
    return (
      this.tasq.setpointRecord &&
      this.tasq.setpointRecord.last_week_open_tp
    );
  }

  get getCurrentWellSetpointData() {
    return setpointV2Module.currentWellSetpointData;
  }

  updateSetpoints(value) {
    this.$emit("update-setpoints", value);
  }

  events = [
    {
      id: 1,
      eventDate: "2023-10-01",
      productionImpactValue: 0.3,
    },
    {
      id: 2,
      eventDate: "2023-10-03",
      productionImpactValue: 0,
    },
    {
      id: 3,
      eventDate: "2023-10-04",
      productionImpactValue: -10,
    },
    {
      id: 4,
      eventDate: "2023-10-05",
      productionImpactValue: 35,
    },
    {
      id: 5,
      eventDate: "2023-10-07",
      productionImpactValue: 20,
    },
  ];

  updateInputKey(e) {
    this.isLoadingData = true;
    console.log(e);
    this.$nextTick(() => {
      this.isLoadingData = false;
    });
  }

  getImpactClass(value) {
    if (value < 0) {
      return value <= -30 ? "text-red-500" : "text-yellow-500";
    } else if (value > 0) {
      return "text-green-500";
    }else{
      return "text-green-500";
    }
  }

  getImpactState(value) {
    if (value < 0) {
      return value <= -30 ? "MAJOR OUTAGE" : "MODERATE DECREASE";
    } else if (value > 0) {
      return "SIGNIFICANT INCREASE";
    } else {
      return "STABLE";
    }
  }

  get isValidForm() {
    if (this.acceptedValue) {
      return true;
    }
    return false;
  }

  changepoints: any = [];

  inputKeys: any = [];

  async created() {
    this.isLoadingData = true;
    await this.fetchSetpointEvents();
  }

  selectObjectKeyWithHigherMedianSigPercChange(data) {
    // Calculate the absolute value of median_sig_perc_change for each object.
    const absoluteMedianSigPercChange = {};
    for (const objectKey in data) {
      const objectData = data[objectKey];
      absoluteMedianSigPercChange[objectKey] = Math.abs(
        objectData["median_sig_perc_change"]
      );
    }

    // Sort the objects by the absolute value of median_sig_perc_change in descending order.
    const sortedObjects: any = Object.entries(absoluteMedianSigPercChange).sort(
      (a: any, b: any) => b[1] - a[1]
    );

    // Select the first object key from the sorted list.
    const objectKey = sortedObjects[0][0];

    return objectKey;
  }

  isNumberConvertable(value) {
    // Check if the value is already a number.
    if (typeof value === "number") {
      return true;
    }

    // Try to convert the value to a number.
    const convertedValue = Number(value);

    // Check if the converted value is a valid number.
    if (isNaN(convertedValue)) {
      return false;
    }

    // The value can be converted to a number.
    return true;
  }

  async fetchSetpointEvents() {
    this.isLoadingData = true;
    this.selectedInput = "";
    try {
      if (this.tasq && this.tasq.setpointAccepted === true) {
        this.message = "Setpoint Accepted!";
      } else if (this.tasq && this.tasq.setpointAccepted === false) {
        this.message = "Setpoint Rejected!";
      }

      // const filterPayload = {
      //   operator: operatorDetails["operatorName"],
      //   node_name: this.tasq.wellName,
      // };
       const changepoints =  gptModule.setpointSummary
       await sleep(2000)

      if (changepoints) {
        this.changepoints = changepoints;
        console.log(this.changepoints);

        this.inputKeys = Object.keys(this.changepoints);

        this.selectedInput = this.selectObjectKeyWithHigherMedianSigPercChange(
          this.changepoints
        );
      }
    } catch (error) {
      console.log(error);
    }finally{
      this.isLoadingData = false;
    }


  }

  get selectedKeyData() {
    const data = JSON.parse(JSON.stringify(this.changepoints[this.selectedInput]));
    if (data.events) {
      const events = data.events.map((e) => {
        return {
          ...e,
          formattedDate: this.formatDate(e.date),
          dateTime: new Date(e.date).getTime(),
        };
      });
      data["events"] = events.sort((a, b) => b.dateTime - a.dateTime);
    }
    if (data.events && !this.showAllEvents) {
      data.events = data.events.slice(0, 5);
    }
    return data;
  }

  isMedianPerPostive(val){
    if(this.isNumberConvertable(val)){
      if (val < 0) {
      return val <= -30 ? "text-accentError200 bg-accentError200" : "text-yellow-500 bg-yellow-500";
    } else if (val > 0) {
      return "text-accentSuccess200 bg-accentSuccess200";
    }
    }else{
      return "text-accentSuccess200 bg-accentSuccess200";
    }
  }

  isMedianPerPostive2(val){
    if(this.isNumberConvertable(val)){
      if (val < 0) {
      return val <= -30 ? "text-accentError200" : "text-yellow-500";
    } else if (val > 0) {
      return "text-accentSuccess200 ";
    }
    }else{
      return "text-accentSuccess200 ";
    }
  }


  formatDate(date) {
    const options: any = { month: "short", day: "numeric", year: "numeric" };

    let dateObj = new Date(date);

    let startFormatted = dateObj.toLocaleDateString("en-US", options);

    return `${startFormatted}`;
  }

  roundToInteger(number) {
    if (number) {
      return Math.round(number);
    }
    return number;
  }
}
